import React from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import FieldContainer from '../FieldContainer';

const TextAreaField = ({ formikProps, proportion, id, label, labelColor, placeholder, minRows, maxRows, disabled }) => {
  const { errors, touched, getFieldProps } = formikProps;

  return (
    <Stack spacing={1}>
      <FieldContainer proportion={proportion} label={label} labelColor={labelColor}>
        <TextField
          sx={{
            '& .MuiInputBase-root': { borderRadius: '10px', fontSize: '16px', lineHeight: 1.43 },
            '& .MuiInputBase-input': { color: '#737791', fontSize: '16px' },
            '& fieldset': {
              borderColor: '#EEF0F2',
            }
          }}
          fullWidth
          id={id}
          placeholder={placeholder}
          multiline
          minRows={minRows}
          maxRows={maxRows}
          {...getFieldProps(id)}
          error={Boolean(touched[id] && errors[id])}
          helperText={touched[id] && errors[id]}
          disabled={disabled}
        />
      </FieldContainer>
    </Stack>
  );
};

export default TextAreaField;
