import mainStyle from "./layout.module.scss";
import { useState, useEffect, useRef } from "react";
import { useWebSocket } from "hook/WebSocketContext";
import { useOutletContext } from "react-router-dom";
import { useAuth } from "../../hook/AuthContext";
import { isTokenExpired } from "utils/auth";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import Grid from "@mui/material/Grid";
import Headers from "../Header";
import Sidebar from "../Sidebar";
import MobileMenu from "../MobileMenu";
import Footer from "../Footer";
import Box from "@mui/material/Box";

/* import { useNotification } from "hook/NotificationContext"; */
import { useMediaQuery, useTheme } from "@mui/material";

// page utils
const Layout = () => {
  const ws = useWebSocket();

  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const [isWebSocketInitialized, setIsWebSocketInitialized] = useState(false);
  const [wsData, setWsData] = useState({
    updateTag: "",
    updateData: {},
  });

  /* const { setUnReadCount } = useNotification();
 */
  const theme = useTheme();
  const isMobilePPI = useMediaQuery(theme.breakpoints.down(431)); // 390x844, 430x932

  useEffect(() => {
    if (ws) {
      console.log("Layout WebSocket connection");
      setIsWebSocketInitialized(true); // WebSocket 已經初始化完畢

      // ws.send = () => {
      //   console.log('WebSocket send JSON');
      // };
      // ws.send(JSON.stringify(sendParam));

      // updateTag > 'clean_tag' & 'send' 是固定詞，不可用前述2個

      ws.onmessage = (message) => {

        const data = JSON.parse(message.data);
        const resource = data.resource;
        console.log("======== ws.onmessage ========");
        // console.log(resource);

        const matchResource = (pattern) => {
          const regex = new RegExp(pattern);
          return regex.test(resource);
        };

        switch (true) {
          case matchResource("^/boxes$"):
            // Handle /boxes/{id}
            console.log("Box data:", data);
            // Update state or perform other actions

            if (data.data) {
              let updateData = cloneDeep(wsData);
              if (data.verb === "notifyRead") {
                updateData.updateTag = "box_read";
                updateData.updateData = data.data;
                setWsData(updateData);
              }
            }

            break;

          case matchResource("^/boxes/\\d+/lights/\\d+$"):
            // Handle /boxes/{id}/lights/{id}
            console.log("Light data:", data);

            // if (data.data) {
            //   const lightId = parseInt(resource.split('/').pop(), 10);
            //   setSwitchStates((prevStates) => {
            //     const newStates = [...prevStates];
            //     newStates[lightId] = data.data.glowing;
            //     return newStates;
            //   });
            // }

            if (data.data) {
              let updateData = cloneDeep(wsData);
              if (data.verb === "notifyUpdate") {
                updateData.updateTag = "light_update";
                updateData.updateData = data;
                setWsData(updateData);
              }
            }

            break;

          case matchResource("^/boxes/\\d+/plugs/\\d+$"):
            // Handle /boxes/{id}/plugs/{id}
            console.log("Plug data:", data);
            // Update state or perform other actions

            if (data.data) {
              let updateData = cloneDeep(wsData);
              if (data.verb === "notifyUpdate") {
                updateData.updateTag = "plug_update";
                updateData.updateData = data;
                setWsData(updateData);
              }
            }
            break;

          case matchResource("^/boxes/\\d+/sphygmometers$"):
            // Handle /boxes/{id}/sphygmometers
            console.log("Sphygmometer data:", data);
            // Update state or perform other actions
            break;

          case matchResource("^/users(/\\d*)?$"):
            // Handle /users or /users/{id}
            console.log("User data:", data);
            // Update state or perform other actions
            break;

          case matchResource("^/users/\\d+/reports$"):
            // Handle /users/{id}/reports
            console.log("reports data:", data);
            // Update state or perform other actions
            if (data.data) {
              let updateData = cloneDeep(wsData);
              if (data.verb === "notifyCreate") {
                updateData.updateTag = "report_creat";
                updateData.updateData = data.data;
                setWsData(updateData);
              } else if (data.verb === "notifyRead") {
                updateData.updateTag = "report_read";
                updateData.updateData = data.data;
                setWsData(updateData);
              }
            }
            break;

          case matchResource("^/boxes/\\d+/events$"):
            // Handle /boxes/{box-id}/events
            console.log("Box events data:", data);
            // Update state or perform other actions

            if (data.data) {
              let updateData = cloneDeep(wsData);
              if (data.verb === "notifyRead") {
                updateData.updateTag = "event_read";
                updateData.updateData = data.data;
                setWsData(updateData);
                /* if (
                  data.data.category ===
                  "reportAbnormal, notTakeBloodPressure, notTakeMedicine, abuse, falldown, sleepingSickness, takeMedicine, takeBloodPressure, outside, hospital, other"
                ) {
                  localStorage.setItem(
                    "ackCount",
                    data.data.ackCount.toString()
                  );
                  const localAckCount = parseInt(
                    localStorage.getItem("ackCount")
                  );
                  setUnReadCount(localAckCount);
                } */
              } else if (data.verb === "notifyUpdate") {
                updateData.updateTag = "event_update";
                updateData.updateData = data.data;
                setWsData(updateData);
              }
            }

            break;

          case matchResource("^/events$"):
            // Handle /boxes/{id}/plugs/{id}
            console.log("Event data:", data);
            // Update state or perform other actions
            /* if (data.data) {
              let updateData = cloneDeep(wsData);
              updateData.updateTag = "abnormalEvent";
              updateData.updateData = data.data[0];
              setWsData(updateData);
            } */
            if (data.data) {
              let updateData = cloneDeep(wsData);
              if (data.verb === "notifyCreate") {
                updateData.updateTag = "abnormalEvent";
                updateData.updateData = data.data;
                setWsData(updateData);
                /* let localAckCount =
                  parseInt(localStorage.getItem("ackCount")) || 0;
                localAckCount = localAckCount + 1;

                localStorage.setItem("ackCount", localAckCount.toString());
                const localGetAckCount =
                  parseInt(localStorage.getItem("ackCount")) || 0;
                setUnReadCount(localGetAckCount); */
              }
            }
            break;

          case matchResource("^/users/\\d+/crons(/\\d*)?$"):
            console.log("Recipient Setting Cron Data", data);
            if (data.data && data.verb === "notifyRead") {
              let updateData = cloneDeep(wsData);
              updateData.updateTag = "allCron";
              updateData.updateData = data.data;
              setWsData(updateData);
            }

            if (data.data && data.verb === "notifyCreate") {
              let updateData = cloneDeep(wsData);
              updateData.updateTag = "createCron";
              updateData.updateData = data.data;
              setWsData(updateData);
            }
            if (data && data.verb === "notifyDelete") {
              let updateData = cloneDeep(wsData);
              updateData.updateTag = "deleteCron";
              updateData.updateData = data;
              setWsData(updateData);
            }

            if (data && data.verb === "notifyUpdate") {
              let updateData = cloneDeep(wsData);
              updateData.updateTag = "updateCron";
              updateData.updateData = data;
              setWsData(updateData);
            }
            break;

          case matchResource("^/homegroups/\\d+/members/\\w+$"):
            let updateData = cloneDeep(wsData);
            updateData.updateTag = "homegroups_read";
            updateData.updateData = data.data;
            console.log("Homegroups  Data", data.data);
            setWsData(updateData);
            break;

          case matchResource("^/users/\\d+/crons/calendar(/\\d*)?$"):
            if (data.data && data.verb === "notifyRead") {
              let updateData = cloneDeep(wsData);
              updateData.updateTag = "read_crons_calendar";
              updateData.updateData = data.data;
              setWsData(updateData);
            }
            break;

          default:
            console.log("Unknown resource:", resource);
            console.log("data:", data);
            break;
        }
        // no token, return to Login.
        if (data.errorCode === 401) {
          localStorage.clear();
          navigate("/");
        }
      };

      ws.onclose = () => {
        console.log("WebSocket connection closed");
      };

      setSocket(ws);
    }
  }, [ws]);

  const ref = useRef(null)
  // useEffect(() => {
  //   let contentHeight = ref.scrollHeight
  //   console.log(contentHeight)
  // }, []);

  return (
    <>
      <div className={mainStyle["body-container"]} >
        { isMobilePPI && <>
          <MobileMenu />
          {isWebSocketInitialized && socket && (
            <>
            <div className={mainStyle["mobile-container"]}>
              <Outlet context={[wsData, setWsData]} />
            </div>
            {/* <div className={mainStyle["footer-container"]}>
              <Grid container alignItems="center" justifyContent="space-evenly" sx={{p: 1}} >
                <div style={{color: 'rgb(109, 114, 140)'}} >© 2024 D-link Corporation. All rights reserved.</div>
              </Grid>
            </div> */}
            </>
          )}
        </>}
        { ! isMobilePPI && <>
          <Sidebar />
          {isWebSocketInitialized && socket && (
            <>
              <div className={mainStyle["layout-container"]}>
                <div className={mainStyle["content-container"]}>
                  <div className={mainStyle["main-container"]}>
                    <div className={mainStyle["header-container"]}>
                      <Headers wsData={wsData} setWsData={setWsData} />
                    </div>
                    <div ref={ref} className={mainStyle["outlet-container"]}>
                      <Outlet context={[wsData, setWsData]} />
                    </div>
                  </div>

                  <div className={mainStyle["footer-container"]}>
                    <Footer />
                  </div>
                </div>

              </div>
            </>
            )}
        </>}
      </div>
    </>
  );
};

export default Layout;
