import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [unreadCount, setUnReadCount] = useState(0);
  return (
    <NotificationContext.Provider
      value={{
        unreadCount,
        setUnReadCount,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
