export const CHINESE_MONTHS = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];

export const WEEK_DAYS = ["日", "一", "二", "三", "四", "五", "六"];

export const TYPE_OPTIONS = {
  ['outside']: '外出活動',
  ['takeBloodPressure']: '生理量測',
  ['takeMedicine']: '用藥',
  ['hospital']: '醫療',
  ['other']: '其他',
}
