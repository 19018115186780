import React, { useState } from 'react';

import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

import FieldContainer from '../FieldContainer';

import { WEEK_DAYS } from "./const";

import { ReactComponent as InputTimeIcon } from 'assets/img/icon/icn_dropdown_time_default.svg';
import { ReactComponent as InputTimeActivedIcon } from 'assets/img/icon/icn_dropdown_time_actived.svg';
import { ReactComponent as ScheduleIcon } from 'assets/img/icon/icn_dropdown_schedule_default.svg';
import { ReactComponent as ScheduleActivedIcon } from 'assets/img/icon/icn_dropdown_schedule_actived.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/img/icon/btn_sidemenu_arrow_down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/img/icon/btn_sidemenu_arrow_upper.svg';
import { ReactComponent as ArrowBackIcon } from 'assets/img/icon/btn_arrow_dropdown_left.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/img/icon/btn_arrow_dropdown_right.svg';
import { ReactComponent as ArrowBackDisabledIcon } from 'assets/img/icon/btn_arrow_dropdown_left_disabled.svg';
import { ReactComponent as ArrowForwardDisabledIcon } from 'assets/img/icon/btn_arrow_dropdown_right_disabled.svg';

const CustomToolbar = ({ value, onViewChange, onChange }) => {
  const date = dayjs(value);
  const isAM = date.hour() < 12;

  function handleToggleAmPm(isAmSelected) {
    const newDate = isAmSelected
      ? date.hour(date.hour() % 12)
      : date.hour((date.hour() % 12) + 12);

    onChange(newDate.toDate());
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center">
        <Box sx={{
          height: '100%',
          width: '150px',
          margin: '10px',
        }}>
          <Typography sx={{ fontSize: '14px', lineHeight: 1.43, color: '#737791', margin: '10px' }}>
            選擇日期和時間
          </Typography>
          <Typography sx={{ fontSize: '14px', lineHeight: 1.43, color: '#737791', margin: '10px' }}>
            {date.format('YYYY 年')}
          </Typography>
          <Typography sx={{ fontSize: '24px', fontWeight: 600, color: '#424962', margin: '10px' }}>
            {date.format('MM 月 DD 日')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{
          height: '75%',
          width: '150px',
          margin: '10px',
          display: 'flex',
          alignItems: 'center',
        }}>
          <Button onClick={() => onViewChange('hours')}>
            <Typography
              sx={{ fontSize: '24px', fontWeight: 600, lineHeight: 1.43, color: '#737791' }}
            >
              {date.format('hh')}
            </Typography>
          </Button>
          <Typography sx={{ fontSize: '24px', fontWeight: 600, lineHeight: 1.43, color: '#737791' }}>:</Typography>
          <Button onClick={() => onViewChange('minutes')}>
            <Typography
              sx={{ fontSize: '24px', fontWeight: 600, lineHeight: 1.43, color: '#737791' }}
            >
              {date.format('mm')}
            </Typography>
          </Button>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
            <Typography
              sx={{
                width: '35px',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: 1.25,
                color: isAM ? '#424962' : '#737791',
                cursor: 'pointer',
                margin: '10px 0'
              }}
              onClick={() => handleToggleAmPm(true)}
            >
              上午
            </Typography>
            <Typography
              sx={{
                width: '35px',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: 1.25,
                color: isAM ? '#737791' : '#424962',
                cursor: 'pointer',
                margin: '10px 0'
              }}
              onClick={() => handleToggleAmPm(false)}
            >
              下午
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const CustomDay = (props) => {
  const { day, outsideCurrentMonth, ...other } = props;

  return (
    <PickersDay
      {...other}
      day={day}
      outsideCurrentMonth={outsideCurrentMonth}
      sx={{
        maxHeight: 'none',
        "&.MuiPickersDay-root": {
          position: 'relative',
          border: 'none',
          color: outsideCurrentMonth ? '#cccccc' : '#737791',
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          margin: '0 auto',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: 1.43,
        },
        "&.Mui-selected": {
          backgroundColor: '#0087a9 !important',
          color: '#ffffff',
        },
      }}
    />
  )
}

const CustomActionBar = ({ onCancel, onAccept }) => (
  <Box display="flex" justifyContent="flex-end" margin="20px">
    <Button
      sx={{
        width: '60px',
        height: '40px',
        borderRadius: '10px',
        borderColor: '#0087a9',
        color: '#0087a9',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 1.43,
        marginRight: '20px',
      }}
      variant="outlined"
      onClick={onCancel}>
      取消
    </Button>
    <Button
      sx={{
        width: '60px',
        height: '40px',
        borderRadius: '10px',
        backgroundColor: '#0087a9',
        color: '#ffffff',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 1.43,
        marginRight: '20px',
      }}
      variant='contained'
      onClick={onAccept}>
      確認
    </Button>
  </Box>
);

const PickTimeField = ({ formikProps, proportion, id, label, labelColor, value, onChange, disabled }) => {
  const [view, setView] = useState('day');
  const [viewDate, setViewDate] = useState(value);
  const { errors, touched } = formikProps;
  const hasError = Boolean(touched[id] && errors[id]);

  function handleFormat(currentValue) {
    const date = dayjs(currentValue);
    const period = date.hour() < 12 ? '上午' : '下午';
    return `yyyy年MM月dd日 ${period} hh:mm`;
  }

  return (
    <Stack spacing={1}>
      <FieldContainer proportion={proportion} label={label} labelColor={labelColor}>
        <FormControl
          fullWidth
          error={hasError}
        >
          <MobileDateTimePicker
            sx={{
              '& .MuiInputBase-root': { borderRadius: '10px', fontSize: '16px', lineHeight: 1.43 },
              '& .MuiInputBase-input': { color: '#737791', fontSize: '16px' },
              '& fieldset': {
                borderColor: hasError ? '#D3302F' : '#EEF0F2',
              },
            }}
            view={view}
            onViewChange={(view) => setView(view)}
            value={value}
            format={handleFormat(value)}
            onChange={onChange}
            onYearChange={(year) => setViewDate(year)}
            onMonthChange={(month) => setViewDate(month)}
            dayOfWeekFormatter={(date) => WEEK_DAYS[dayjs(date).day()]}
            showDaysOutsideCurrentMonth
            slots={{
              toolbar: CustomToolbar,
              day: CustomDay,
              switchViewIcon: () => view === 'year' ? <ArrowUpIcon /> : <ArrowDownIcon />,
              leftArrowIcon: () => view === 'hours' ? <ArrowBackDisabledIcon /> : <ArrowBackIcon />,
              rightArrowIcon: () => view === 'minutes' ? <ArrowForwardDisabledIcon /> : <ArrowForwardIcon />,
              actionBar: CustomActionBar
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end" sx={{ cursor: 'pointer', width: '16px', height: '16px' }}>
                      <InputTimeIcon />
                    </InputAdornment>
                  )
                }
              },
              paper: {
                sx: { zIndex: 2000 }
              },
              layout: {
                sx: {
                  display: 'block',
                  '& .MuiPickersLayout-contentWrapper': {
                    borderBottom: 'solid 1px #F5F6F7',
                    height: '420px',
                  },
                  '& .MuiDateCalendar-root': {
                    width: '380px',
                    height: '400px',
                    maxHeight: 'none',
                  },
                  '& .MuiDayCalendar-slideTransition': {
                    minHeight: '400px',
                  },
                  '& .MuiDayCalendar-weekDayLabel': {
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: 1.43,
                    color: '#737791',
                    width: '50px',
                  },
                  ...(view === 'year' && {
                    '& .MuiYearCalendar-root': {
                      width: '380px',
                    },
                    '& .MuiPickersYear-yearButton': {
                      position: 'relative',
                      color: '#737791',
                      fontSize: '14px',
                      lineHeight: 1.43,
                      fontWeight: 600,
                      "&.Mui-selected": {
                        backgroundColor: '#0087a9 !important',
                        color: '#ffffff',
                      },
                    }
                  }),
                  '& .MuiTimeClock-root': {
                    top: '65px',
                    width: '350px',
                    height: '350px',
                    maxHeight: '400px',
                    overflow: 'visible',
                    '& .MuiClock-pin': {
                      backgroundColor: '#0087a9',
                    },
                    '& .MuiClockPointer-root': {
                      backgroundColor: '#0087a9',
                    },
                    '& .MuiClockNumber-root': {
                      color: '#424962',
                      fontSize: '12px',
                      lineHeight: 1.67,
                    },
                    '& .MuiClockNumber-root.Mui-selected': {
                      backgroundColor: '#0087a9',
                      color: '#ffffff',
                    },
                    '& .MuiPickersArrowSwitcher-spacer': {
                      width: '44px',
                    },
                    '& .MuiTimeClock-arrowSwitcher': {
                      right: '-10px',
                      top: '-30px',
                    }
                  },
                },
              },
              dialog: {
                sx: {
                  "& .MuiDialog-paper": {
                    borderRadius: "26px",
                  },
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "460px",
                    },
                  },
                }
              },
              tabs: {
                dateIcon: view === 'year' || view === 'day' ? <ScheduleActivedIcon /> : <ScheduleIcon />,
                timeIcon: view === 'hours' || view === 'minutes' ? <InputTimeActivedIcon /> : <InputTimeIcon />,
                sx: {
                  borderTop: "solid 1px #F5F6F7",
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#0087a9"
                  },
                  ".Mui-selected": {
                    backgroundColor: "#ebf5ff"
                  }
                }
              },
              calendarHeader: {
                format: `${dayjs(viewDate).year()} 年 ${dayjs(viewDate).month() + 1} 月`,
                sx: {
                  padding: 0,
                  '& .MuiPickersCalendarHeader-label': {
                    fontSize: '16px',
                    fontWeight: 600,
                    lineHeight: 1.25,
                    color: '#737791'
                  },
                },
              },
            }}
            disabled={disabled}
          />
          {touched[id] && errors[id] && (
            <FormHelperText>{errors[id]}</FormHelperText>
          )}
        </FormControl>
      </FieldContainer>
    </Stack>
  );
}

export default PickTimeField;