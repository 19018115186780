import React from 'react';

import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import InputTextField from 'mantisComponents/InputTextField';
import PickTimeField from 'mantisComponents/PickTimeField';
import TextAreaField from 'mantisComponents/TextAreaField';
import SwitchField from 'mantisComponents/SwitchField';
import SelectField from 'mantisComponents/SelectField';

import { ReactComponent as InputLocationIcon } from 'assets/img/icon/icn_dropdown_location.svg';

import { TYPE_OPTIONS, EDIT_TYPE_OPTIONS } from './const'

const CalendarModalContent = ({ formik, superAdminData, elderData, currentProsessState }) => {
  const { values, setFieldValue } = formik;
  const isInViewMode = currentProsessState === 'View'
  const isInEditOrAddMode = currentProsessState === 'Edit' || currentProsessState === 'Add'

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputTextField
            formikProps={formik}
            proportion={[1.5, 10.5]}
            id="title"
            label="標題"
            labelColor="#424962"
            placeholder="新增標題"
            disabled={isInViewMode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PickTimeField
            formikProps={formik}
            proportion={[3, 9]}
            id="start"
            label="開始時間"
            labelColor="#424962"
            value={new Date(values.start)}
            onChange={(date) => setFieldValue('start', date)}
            disabled={isInViewMode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputTextField
            formikProps={formik}
            proportion={[3, 9]}
            id="location"
            label="地點"
            labelColor="#424962"
            placeholder="新增地點"
            icon={<InputLocationIcon />}
            disabled={isInViewMode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PickTimeField
            formikProps={formik}
            proportion={[3, 9]}
            id="end"
            label="結束時間"
            labelColor="#424962"
            value={new Date(values.end)}
            onChange={(date) => setFieldValue('end', date)}
            disabled={isInViewMode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextAreaField
            formikProps={formik}
            proportion={[3, 9]}
            id="description"
            label="事件描述"
            labelColor="#424962"
            placeholder="事件描述內容"
            minRows="2"
            maxRows="5"
            disabled={isInViewMode}
          />
        </Grid>
        <Grid item xs={12}>
          <SwitchField
            formikProps={formik}
            id="allDay"
            label="整天"
            value={values.allDay}
            onChange={() => setFieldValue('allDay', !values.allDay)}
            disabled={isInViewMode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            multiple
            formikProps={formik}
            proportion={[3, 9]}
            id="elder"
            label="老年人"
            labelColor="#424962"
            options={elderData}
            disabled={isInViewMode}
          />
        </Grid>
        <Grid item xs={12} md={6} />
        <Grid item xs={12} md={6}>
          <SelectField
            multiple
            formikProps={formik}
            proportion={[3, 9]}
            id="notifiedMembers"
            label="被通知對象"
            labelColor="#424962"
            options={superAdminData}
            placeholder="新增被通知對象"
            disabled={isInViewMode}
          />
        </Grid>
        <Grid item xs={12} md={6} />
        <Grid item xs={12} md={6}>
          <SelectField
            formikProps={formik}
            proportion={[3, 9]}
            id="type"
            label="活動類型"
            labelColor="#424962"
            options={isInEditOrAddMode ? EDIT_TYPE_OPTIONS : TYPE_OPTIONS}
            disabled={isInViewMode}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

export default CalendarModalContent;