export const selectDate = [
  {
    id: 0,
    content: "全部",
  },
  {
    id: 1,
    content: "緊急通知",
  },
  {
    id: 2,
    content: "提醒",
  },
  {
    id: 3,
    content: "一般通知",
  },
];

export const categoryMapping = {
  "全部": "reportAbnormal, notTakeBloodPressure, notTakeMedicine, abuse, falldown, sleepingSickness, takeMedicine, takeBloodPressure, outside, hospital, other, deviceChange",
  "緊急通知": "reportAbnormal, falldown, abuse, sleepingSickness, notTakeMedicine, notTakeBloodPressure",
  "提醒": "takeMedicine, takeBloodPressure, outside, hospital, other",
  "一般通知": "deviceChange",
};
