import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const FieldContainer = ({ proportion, label, labelColor, children }) => (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={0}>
        <Grid item xs={proportion && proportion[0]}>
            <label style={{ color: labelColor, lineHeight: 1.25, fontSize: '16px', fontWeight: 600 }}>{label}</label>
        </Grid>
        <Grid item xs={proportion && proportion[1]}>
            <Box>
                {children}
            </Box>
        </Grid>
    </Grid>
);

export default FieldContainer;