import "./avatarGroup.component.scss";

const AvatarGroup = ({ avatarContainerMargin, avatarWidthAndHeight, data, length }) => (
  <div style={{ margin: avatarContainerMargin }} className="avatarGroup">
    {
      data.map((item, index) => (
        index <= length && <img
          style={{ width: avatarWidthAndHeight, height: avatarWidthAndHeight, marginLeft: index === 0 ? 0 : '-15px' }}
          className="avatar"
          key={index}
          src={item.portrait}
          alt={item.name}
        />
      ))
    }
    {data.length > length && <span className="avatar more">+{length}</span>}
  </div>
);

export default AvatarGroup;