import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function toUnixTimestamp(input) {
  const convert = (date) => {
    const timestamp = dayjs(date).unix();
    if (isNaN(timestamp)) {
      throw new Error(`Invalid date: ${date}`);
    }
    return timestamp;
  };

  if (Array.isArray(input)) {
    return input.map(date => convert(date));
  } else {
    return convert(input);
  }

  // Input: "Sun Oct 27 2024 00:00:00 GMT+0800 (台北標準時間)";
  // Input: ["Sun Oct 27 2024 00:00:00 GMT+0800 (台北標準時間)", "Fri Nov 01 2024 00:00:00 GMT+0800 (台北標準時間)"];

  // 也可以傳入  2024-10-25T00:00:00.000Z 格式

  // Output: 1727712000
  // Output: [1727712000, 1728067200]
}

export function formatTimestampToTime(timestamp) {
  const date = new Date(timestamp * 1000);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const period = hours >= 12 ? '下午' : '上午';
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');

  return `${formattedHours}:${minutes} ${period}`;
  
  // Input: 1729987380;
  // Output: 08:00 下午
}

export function unixToISO8601(unixTimestamp) {
  if (typeof unixTimestamp !== 'number') {
    throw new Error('Invalid Unix timestamp');
  }

  // Convert Unix timestamp to dayjs and format as ISO 8601 string
  return dayjs.unix(unixTimestamp).utc().toISOString();

  // Input: 1729843200;

  // Output: "2024-10-25T00:00:00.000Z"
}

export function formatToISOString(date) {
  if (!dayjs(date).isValid()) {
    throw new Error('Invalid date');
  }
  // Convert to UTC and format as ISO string
  return dayjs(date).utc().toISOString();

  // Input: Fri Nov 15 2024 10:57:59 GMT+0800 (台北標準時間);

  // Output: "2024-10-25T00:00:00.000Z"
}

export function convertDateFormat(dateStr) {
  const date = new Date(dateStr);

  const weekday = date.toLocaleDateString('en-US', { weekday: 'short', timeZone: 'Asia/Taipei' });
  const month = date.toLocaleDateString('en-US', { month: 'short', timeZone: 'Asia/Taipei' });
  const day = date.toLocaleDateString('en-US', { day: '2-digit', timeZone: 'Asia/Taipei' });
  const year = date.toLocaleDateString('en-US', { year: 'numeric', timeZone: 'Asia/Taipei' });

  const time = date.toLocaleTimeString('en-US', {
    hour12: false,
    timeZone: 'Asia/Taipei',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  const timeZone = "GMT+0800";
  const timeZoneName = "台北標準時間";

  return `${weekday} ${month} ${day} ${year} ${time} ${timeZone} (${timeZoneName})`;

  // Input: "2024-10-25T00:00:00.000Z"

  // Output: Fri Nov 15 2024 10:57:59 GMT+0800 (台北標準時間);
}

export function toAmPmTime(date) {
  const d = new Date(date);

  let hours = d.getHours();
  let minutes = d.getMinutes();

  const ampm = hours >= 12 ? '下午' : '上午';

  hours = hours % 12;
  hours = hours ? hours : 12;

  minutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}:${minutes} ${ampm}`;

  // Input: "2024-10-25T00:00:00.000Z";

  // Output: 08:00 上午
}